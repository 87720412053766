import { createGlobalStyle } from "styled-components"
import OpenSansRegular from "./OpenSansRegular.ttf"
import OpenSansBold from "./OpenSansBold.ttf"
import Raleway from "./Raleway.ttf"
import Lobster from "./Lobster.ttf"
import JosefinSans from "./JosefinSans.ttf"

export default createGlobalStyle`
    @font-face {
        font-family: 'Open Sans Regular';
        src: url(${OpenSansRegular});
    }
    @font-face {
        font-family: 'Open Sans Bold';
        src: url(${OpenSansBold});
    }
    @font-face {
        font-family: 'Raleway';
        src: url(${Raleway});
    }
    @font-face {
        font-family: 'Lobster';
        src: url(${Lobster});
    }
    @font-face {
        font-family: 'JosefinSans';
        src: url(${JosefinSans});
    }
`
