import * as React from "react"
import { Link } from "gatsby"
import { Menu, Drawer } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import {
  UserOutlined,
  FormatPainterOutlined,
  MailOutlined,
  MenuOutlined,
  CommentOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons"
import styled from "styled-components"

const StyledImageContainer = styled.div`
  margin: auto;
`

const drawerHeaderStyle = {
  fontFamily: "JosefinSans",
}

const drawerBodyStyle = {
  padding: "0px",
}

const iconStyle = {
  fontSize: "18px",
}

const { SubMenu } = Menu

export const MobileNavbar = () => {
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false)
  const [selectedKey, setSelectedKey] = React.useState("")

  const handleClick = e => {
    setSelectedKey(e.key)
  }

  return (
    <>
      <div>
        <MenuOutlined onClick={() => setDrawerIsOpen(true)} />
      </div>
      <StyledImageContainer>
        <Link to="/">
          <StaticImage
            src="../../images/logo.png"
            alt="logo"
            width={50}
            layout="fixed"
          />
        </Link>
      </StyledImageContainer>
      <Drawer
        title="Menu"
        placement="left"
        closable
        visible={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
        headerStyle={drawerHeaderStyle}
        bodyStyle={drawerBodyStyle}
      >
        <Menu
          onClick={handleClick}
          selectedKeys={selectedKey}
          defaultOpenKeys={["projects"]}
          mode="inline"
        >
          <Menu.Item key="home" icon={<HomeOutlined style={iconStyle} />}>
            <Link to="/" onClick={() => setDrawerIsOpen(false)}>
              Home
            </Link>
          </Menu.Item>
          <SubMenu
            key="projects"
            title="Projects"
            icon={<FormatPainterOutlined style={iconStyle} />}
          >
            <Menu.ItemGroup title="Residential">
              <Menu.Item key="residential:newHomes">
                <Link
                  to="/projects/residential-newhome/"
                  onClick={() => setDrawerIsOpen(false)}
                >
                  New Homes
                </Link>
              </Menu.Item>
              <Menu.Item key="residential:renovations">
                <Link
                  to="/projects/residential-reno/"
                  onClick={() => setDrawerIsOpen(false)}
                ></Link>
                Renovations
              </Menu.Item>

              <Menu.Item key="residential:all">
                <Link
                  to="/projects/residential/"
                  onClick={() => setDrawerIsOpen(false)}
                >
                  All
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Commercial">
              <Menu.Item key="commercial:shops">
                <Link
                  to="/projects/commercial-shop/"
                  onClick={() => setDrawerIsOpen(false)}
                >
                  Shops
                </Link>
              </Menu.Item>
              <Menu.Item key="commercial:medicalCentres">
                <Link
                  to="/projects/commercial-medcentre/"
                  onClick={() => setDrawerIsOpen(false)}
                >
                  Medical Centres
                </Link>
              </Menu.Item>
              <Menu.Item key="commercial:all">
                <Link
                  to="/projects/commercial/"
                  onClick={() => setDrawerIsOpen(false)}
                >
                  All
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Industrial">
              <Menu.Item key="industrial:all">
                <Link
                  to="/projects/ind/"
                  onClick={() => setDrawerIsOpen(false)}
                >
                  All
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Upcoming Projects">
              <Menu.Item key="upcoming:commercial">
                <Link
                  to="/projects/upcoming-commercial/"
                  onClick={() => setDrawerIsOpen(false)}
                >
                  Commercial
                </Link>
              </Menu.Item>
              <Menu.Item key="upcoming:all">
                <Link
                  to="/projects/upcoming/"
                  onClick={() => setDrawerIsOpen(false)}
                >
                  All
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <Menu.Item
            key="testimonials"
            icon={<CommentOutlined style={iconStyle} />}
          >
            <Link to="/testimonials/" onClick={() => setDrawerIsOpen(false)}>
              Testimonials
            </Link>
          </Menu.Item>
          <Menu.Item key="contact" icon={<MailOutlined style={iconStyle} />}>
            <Link to="/contact/" onClick={() => setDrawerIsOpen(false)}>
              Contact
            </Link>
          </Menu.Item>
          <Menu.Item key="about" icon={<UserOutlined style={iconStyle} />}>
            <Link to="/about" onClick={() => setDrawerIsOpen(false)}>
              About
            </Link>
          </Menu.Item>
          <Menu.Item
            key="dashboard"
            icon={<SettingOutlined style={iconStyle} />}
          >
            <Link to={`/dashboard`} onClick={() => setDrawerIsOpen(false)}>
              Dashboard
            </Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  )
}
