import React from "react"
import { Layout } from "antd"
import "antd/dist/antd.css"
import styled from "styled-components"
import GlobalFonts from "../fonts/fonts"
import { MobileNavbar } from "../components/Navigation/mobile"
import { DesktopNavbar } from "../components/Navigation/desktop"
import { InstagramFilled, LinkedinFilled, MailFilled } from "@ant-design/icons"
import "./layout.css"

const { Header, Footer, Content } = Layout

const Container = styled.div`
  width: 75%;
  padding: 10px;
  margin: auto;
  margin-top: 20px;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
const StyledHeader = styled(Header)`
  background-color: white;
  display: flex;
  justify-content: center;
  font-family: "JosefinSans";
`

const StyledContent = styled(Content)`
  background-color: white;
  font-family: "JosefinSans";
  background-color: #202020;
`

const StyledFooter = styled(Footer)`
  background-color: black;
  height: 200px;
  display: flex;
  justify-content: center;
  font-family: "JosefinSans";
`

const StyledLayout = styled(Layout)`
  background-color: #202020;
  min-height: 100vh;
`

const FooterText = styled.p`
  font-size: 15px;
  color: white;
`

const FooterHeading = styled.h3`
  font-size: 20px;
  color: white;
  text-align: center;
`

const FooterContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const IconContainer = styled.div`
  margin: 0px auto 0px auto;
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  width: 100px;
`

const IconLink = styled.a`
  text-decoration: none;
  color: white;
`

export const SiteLayout = props => {
  const [windowWidth, setWindowWidth] = React.useState("")

  const handleResize = e => {
    setWindowWidth(window.innerWidth)
  }

  React.useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)

    return function cleanup() {
      handleResize()
      window.addEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <StyledLayout>
        <GlobalFonts />
        <StyledHeader>
          {parseInt(windowWidth) < 900 ? <MobileNavbar /> : <DesktopNavbar />}
        </StyledHeader>
        <StyledContent>
          {props.location.pathname === "/" ? (
            props.children
          ) : (
            <Container>{props.children}</Container>
          )}
        </StyledContent>
        <StyledFooter>
          <FooterContainer>
            <FooterHeading>TJBuildingDesigners</FooterHeading>
            <FooterText>Website Created By: Anshul Jangra</FooterText>
            <IconContainer>
              <IconLink
                href="mailto:anshuljangra2000@hotmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <MailFilled />
              </IconLink>
              <IconLink
                href="https://www.instagram.com/anshuljangra_"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramFilled />
              </IconLink>
              <IconLink
                href="https://www.linkedin.com/in/anshuljangra/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinFilled />
              </IconLink>
            </IconContainer>
          </FooterContainer>
        </StyledFooter>
      </StyledLayout>
    </>
  )
}
