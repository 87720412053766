import * as React from "react"
import { Link } from "gatsby"
import { Menu, Avatar } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import {
  UserOutlined,
  FormatPainterOutlined,
  MailOutlined,
  CommentOutlined,
  SettingOutlined,
} from "@ant-design/icons"
import styled from "styled-components"

const StyledImageContainer = styled(Menu.Item)`
  height: 60px;
  vertical-align: middle !important;
  top: 4px !important;
`

const StyledMenu = styled(Menu)`
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  & > li {
    font-size: 18px;
  }
`

const SettingWidget = styled.div`
  bottom: 102px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 32px;
  z-index: 2147483640;
`

const StyledAvatar = styled(Avatar)`
  vertical-align: middle;
  background-color: white;
  color: black;
`

const StyledSettingOutlined = styled(SettingOutlined)`
  margin: 8px !important;
`

const iconStyle = {
  fontSize: "18px",
}

const { SubMenu } = Menu

export const DesktopNavbar = () => {
  const [selectedKey, setSelectedKey] = React.useState("home")
  const handleClick = e => {
    setSelectedKey(e.key)
  }
  return (
    <>
      <SettingWidget>
        <Link to={`/dashboard`}>
          <StyledAvatar size="large" icon={<StyledSettingOutlined />} />
        </Link>
      </SettingWidget>
      <StyledMenu
        onClick={handleClick}
        selectedKeys={selectedKey}
        mode="horizontal"
      >
        <Menu.Item key="about" icon={<UserOutlined style={iconStyle} />}>
          <Link to="/about">About</Link>
        </Menu.Item>
        <SubMenu
          key="projects"
          title="Projects"
          icon={<FormatPainterOutlined style={iconStyle} />}
        >
          <Menu.ItemGroup title="Residential">
            <Menu.Item key="residential:newHomes">
              <Link to="/projects/residential-newhome/">New Homes</Link>
            </Menu.Item>
            <Menu.Item key="residential:renovations">
              <Link to="/projects/residential-reno/"></Link>Renovations
            </Menu.Item>

            <Menu.Item key="residential:all">
              <Link to="/projects/residential/">All</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Commercial">
            <Menu.Item key="commercial:shops">
              <Link to="/projects/commercial-shop/">Shops</Link>
            </Menu.Item>
            <Menu.Item key="commercial:medicalCentres">
              <Link to="/projects/commercial-medcentre/">Medical Centres</Link>
            </Menu.Item>
            <Menu.Item key="commercial:all">
              <Link to="/projects/commercial/">All</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Industrial">
            <Menu.Item key="industrial:all">
              <Link to="/projects/ind/">All</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Upcoming Projects">
            <Menu.Item key="upcoming:commercial">
              <Link to="/projects/upcoming-commercial/">Commercial</Link>
            </Menu.Item>
            <Menu.Item key="upcoming:all">
              <Link to="/projects/upcoming/">All</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <StyledImageContainer key="home">
          <Link to="/">
            <StaticImage
              src="../../images/logo.png"
              alt="logo"
              width={50}
              layout="fixed"
            />
          </Link>
        </StyledImageContainer>
        <Menu.Item
          key="testimonials"
          icon={<CommentOutlined style={iconStyle} />}
        >
          <Link to="/testimonials/">Testimonials</Link>
        </Menu.Item>
        <Menu.Item key="contact" icon={<MailOutlined style={iconStyle} />}>
          <Link to="/contact/">Contact</Link>
        </Menu.Item>
      </StyledMenu>
    </>
  )
}
